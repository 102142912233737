import {defineStore} from "pinia";

export const useSpotlistStore = defineStore("spotlistStore", () => {

    const platformSettings = usePlatformSettings();


    const loading = ref(true);
    const initialError = ref<string>();
    const error = ref<string>();

    const pagesize = ref(15);
    const lastPage = ref(false);

    const sort = ref("createdOn");
    const page = useCookie("spotlistPage", {
      default: () => (0)
    });

    const spotPreviews = ref<Array<SpotPreview>>(new Array<SpotPreview>());
    const regionId = usePlatformSettings().region;


    function loadMore() {
      if (!lastPage.value) {
        page.value = page.value + 1;
        loadSpotPreviews();
      }
    }

    function getParams(initial: boolean): any {

      return {
        page: initial ? 0 : page.value,
        sort: sort.value,
        size: initial ? (page.value + 1) * pagesize.value : pagesize.value
      };
    }

    function loadInitalSpotPreviews(resetPages: boolean = false) {
      lastPage.value = false;
      spotPreviews.value = [];

      if (resetPages) {
        page.value = 0;
      }

      loadSpotPreviews(true);
    }

    function loadSpotPreviews(intial: boolean = false) {
      loading.value = true;
      const params = getParams(intial);
      const regionFilter = ref({});
      if (regionId.value && regionId.value != 0) {
        regionFilter.value = { "regionId": regionId.value };
      }
      const fullParams = Object.assign({}, params, platformSettings.getFilterParams(), regionFilter.value);

      useApi().get<SpotPreviewsResponse>("spotPreviews", {query: fullParams}).then((response) => {
        const newPreviews = response.spotPreviews;
        newPreviews.forEach((spotPreview: any) => {
          spotPreviews.value.push(spotPreview);
        });
        lastPage.value = response.lastPage;
      }).catch((reason) => {
        error.value = "generic";
        console.error("Error loading spot previews", reason.value);
      }).finally(() => {
        loading.value = false;
      });
    }

    return {
        regionId,
        sort,
        page,
        spotPreviews,
        loading,
        initialError,
        error,
        lastPage,
        pagesize,
        loadInitalSpotPreviews,
        loadMore,
    };
});
